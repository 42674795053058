import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import toast from "react-hot-toast";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";


//i18n
import { withTranslation } from "react-i18next";

import * as setup from "models/setup";



const SidebarContent = props => {
  const dispatch = useDispatch();
  const levelTypes = useSelector(state => state.LevelType.data?.results || []);


  const [activeKey, setActiveKey] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [userProfile, setUserProfile] = useState(null)
  const [contents, setContents] = useState([])
  const [listedSession, setListedSession] = useState([])
  const [listedSubjects, setListedSubjects] = useState([])

  const [activeSubjectTab, setActiveSubjectTab] = useState("")
  const [selectedSession, setSelectedSession] = useState("")

  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)

  const ref = useRef();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classNameList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classNameList.add("mm-show");
    }

    if (parent) {
      parent.classNameList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classNameList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classNameList.add("mm-active"); // li
          parent3.childNodes[0].classNameList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classNameList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classNameList.add("mm-show"); // li
              parent5.childNodes[0].classNameList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }
  function groupTopicsByUnitAndSession(topics) {
    return topics.reduce((groupedUnits, topic) => {
      // Find or create the unit group
      let unitGroup = groupedUnits.find(group => group.unit === topic.unit);

      if (!unitGroup) {
        unitGroup = {
          unit: topic.unit,
          unitId: topic.unitId,
          sessions: []
        };
        groupedUnits.push(unitGroup);
      }

      // Find or create the session group within the unit
      let sessionGroup = unitGroup.sessions.find(session => session.session	 === topic.session	);

      if (!sessionGroup) {
        sessionGroup = {
          session	: topic.session	,
          topics: []
        };
        unitGroup.sessions.push(sessionGroup);
      }

      // Add the topic to the session group
      sessionGroup.topics.push(topic);

      return groupedUnits;
    }, []);
  }
  function groupTopicsByUnitAndSession(topics) {
    const grouped = topics.reduce((result, topic) => {
      const { unit, session	 = "No Session" } = topic;

      // Find or create the unit entry
      let unitGroup = result.find(u => u.unit === unit);
      if (!unitGroup) {
        unitGroup = { unit, sessions: [] };
        result.push(unitGroup);
      }

      // Find or create the session entry within the unit
      let sessionGroup = unitGroup.sessions.find(s => s.session	 === session	);
      if (!sessionGroup) {
        sessionGroup = { session	, topics: [] };
        unitGroup.sessions.push(sessionGroup);
      }

      // Add the topic to the correct session
      sessionGroup.topics.push(topic);

      return result;
    }, []);

    return grouped;
  }

  const getAllTopics = () => {
    setup.getAllTopics(`level=${userProfile?.level}`)
      .then(resp => {
        const data = resp.results
        setListedSubjects(groupTopicsByUnitAndSession(data))
        console.log(groupTopicsByUnitAndSession(data))

      }).catch(err => {

      })
  }


  useEffect(() => {
    setSelectedSession(null)
    const m = contents.find((item) => (item?.unit === activeSubjectTab))
    if (!m) { }
    else {
      setListedSession(m?.sessions)
      console.log(m.sessions)
    }

  }, [activeSubjectTab])

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("teutiUser"))
    setUserProfile(profile)
  }, [])

  useEffect(() => {
    if (userProfile) {
      getAllTopics()
    }
  }, [userProfile])


  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("teutiUser"))
    setUserProfile(profile)
  }, [props.updateLocalStorage])
  return (
    <React.Fragment>
      <SimpleBar className="h-100 position-relative" ref={ref}>
        <div id="sidebar-menu" className="" >
          <ul className="metismenu list-unstyled" id="side-menu">
            {userProfile ? (
              <div className="d-flex flex-column text-white p-2">
                <div className="d-flex justify-content-center align-items-center me-1 ">The Learning Areas </div>
                <div className="accordion" >
                  {listedSubjects.map(((item, index) => (
                    <div
                      key={index}
                      className="accordion-item"
                      href={`#${item.name}`}
                      onClick={() => { setActiveIndex(index), setActiveSubjectTab(item?.unit) }}
                    >
                      <h2 className="accordion-header ">
                        <button

                          className={`btn w-100 d-flex justify-content-between ${activeKey === index ? '' : 'collapsed'} 
                     text-${activeKey === index ? 'primary' : 'white'} bg-${activeKey === index ? 'white' : 'primary'} `}


                        >
                          {item?.unit}
                          <span className="accordion-icon">
                            <i className={`fas ${activeKey === index ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'}`}
                            ></i>
                          </span>
                        </button>

                      </h2>
                      <div id="collapseOne" className={`accordion-collapse mt-0 collapse ${activeIndex === index ? 'show' : ''}`}
                        data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                          {item?.sessions.map((title, key) => (
                            <li key={key}>
                              <a
                                className={`p-1 rounded bg-${selectedSession === `${title?.session	}` ? "secondary " : null} text-${selectedSession === `${title?.session	}` ? "white " : "secondary"} font-size-14 mt-0 mx-2 px-2`
                                }
                                onClick={() => { props.setSelectedSession(title), setSelectedSession(title?.session	), props.setSelectedTopics(title?.topics) }}
                              >
                                {props.t(`${title?.session	}`)}
                              </a>
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                  )))}
                </div>

              </div>) : (
              <>
                <li className="menu-title text-white font-size-14">{props.t("The Learning Levels")} </li>
                <div className="accordion" >
                  {levelTypes.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header "
                        onClick={() => setActiveKey(index)}>
                        {(item?.levelTypeName === "Pre-K" || item?.levelTypeName === "Kindergarten") ? (
                          <div className="my-1">
                            {item.levels.map((title, key) => (
                              <li key={key} className="">
                                <a
                                  className={`p-1 rounded bg-${props.selectedLevel === `${title?.levelName}` ? "secondary " : null} text-${props.selectedCategory === `${title?.levelName}` ? "secondary " : "white"} font-size-14 mt-0 mx-2 px-2`
                                  }
                                  onClick={() => { props.setSelectedLevel(title?.levelName) }}
                                >
                                  {props.t(`${title?.levelName}`)}
                                </a>
                              </li>
                            ))}
                          </div>) : (
                          <button

                            className={`btn w-100 d-flex justify-content-between ${activeKey === index ? '' : 'collapsed'} 
                     text-${activeKey === index ? 'primary' : 'white'} bg-${activeKey === index ? 'white' : 'primary'} `}


                          >
                            {item?.levelTypeName}
                            <span className="accordion-icon">
                              <i className={`fas ${activeKey === index ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'}`}
                              ></i>
                            </span>
                          </button>)}

                      </h2>
                      {(item?.levelTypeName === "Pre-K" || item?.levelTypeName === "Kindergarten") ? (null) : (
                        <div id="collapseOne" className={`accordion-collapse mt-0 collapse ${activeKey === index ? 'show' : ''}`}
                          data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            {item.levels.map((title, key) => (
                              <li key={key}>
                                <a
                                  className={`p-1 rounded bg-${props.selectedLevel === `${title?.levelName}` ? "secondary " : null} text-${props.selectedCategory === `${title?.levelName}` ? "secondary " : "white"} font-size-14 mt-0 mx-2 px-2`
                                  }
                                  onClick={() => { props.setSelectedLevel(title?.levelName) }}
                                >
                                  {props.t(`${title?.levelName}`)}
                                </a>
                              </li>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}


                </div>
              </>
            )}

          </ul>
        </div>
        <Col className="text-center text-white " style={{ position: "absolute", bottom: 5, left: 5 }}>
          {new Date().getFullYear()} © Tehuti e-Learning Space.
        </Col>
      </SimpleBar >
    </React.Fragment >
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  selectedLevel: PropTypes.string,
  setSelectedLevel: PropTypes.func
};

export default withRouter(withTranslation()(SidebarContent));
