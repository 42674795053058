import PropTypes from "prop-types";
import React from "react";
import {
  Container,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import VideoApp from "pages/Video";
import MeetingView from "pages/Lectures/MeetingView";
import { FaBook, FaClipboard, FaUserAlt, FaCloud, FaChartPie } from "react-icons/fa";
import { MdNotifications } from "react-icons/md";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement, Tooltip, Legend);

const Dashboard = props => {

  //meta title
  document.title = "Dashboard | Tehuti Learning Platfrom";

  const progressData = {
    datasets: [
      {
        data: [66, 34],
        backgroundColor: ["#4c91f7", "#dbe6fd"],
        hoverBackgroundColor: ["#3b7fe5", "#c3d7fc"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="dashboard-container">
      {/* Main Dashboard */}
      <main className="dashboard">
        <header className="dashboard-header">
          <h1>Welcome Back, <span>User</span>!</h1>
        </header>

        <section className="stats-grid">
          {/* Active Class */}
          <div className="card">
            <h3>📖 Active Class</h3>
            <p>Mathematics | Grade 6</p>
          </div>

          {/* Exam Sessions */}
          <div className="card">
            <h3>📝 Exam Sessions</h3>
            <p>Upcoming: 3</p>
          </div>

          {/* Progress Report */}
          <div className="progress-card">
            <h3>📊 Progress Report</h3>
            <Doughnut data={progressData} />
            <p>66% Completion</p>
          </div>
        </section>
      </main>
    </div>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
