import React, { useEffect, useState, useRef } from 'react'
import { Card, Modal, Overlay, Popover, OverlayTrigger, Row, Col } from "react-bootstrap"

import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
//import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import audioImage from "assets/images/audio.png";
import pdfImage from "assets/images/pdfLogo.png";
import toast from "react-hot-toast";
import { propTypes } from "react-bootstrap/esm/Image";

import * as file from "models/files";
import Audio from './Audio';

const FileCard = props => {
  const [viewPdf, setViewPdf] = useState(false)
  const [show, setShow] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const updateDownload = (params) => {
    file.updateDownload(params)
      .then(resp => {

      }).catch(err => {

      })
  }


  const item = props.item
  const popover = (
    <div className="card bg-white shadow">
      <a className="text-primary"
        onClick={(e) => { setTarget(null), setShowShareModal(true) }}
      >
        <i className="fas fa-share-alt me-2" aria-hidden="true"></i>
        Share
      </a>
      <a
        //href={item?.docURL	}
        //download={item?.title}
        onClick={() => updateDownload(item?._id)}>
        <i className="fas fa-download me-2" aria-hidden="true"></i>
        Download
      </a>
    </div>
  );

  const printPDF = (url) => {
    const printWindow = window.open(url, '_blank');
    printWindow.onload = function () {
      printWindow.print();
    };
  };

  return (
    <div className={`item-container col m-2 col-5 col-md-${props.fileViewer ? "5" : "3"}  col-lg-${props.fileViewer ? "4" : "2"}  `}>

      <div
        className="item-card "
        onClick={() => {
          props.signTrigger === true ? props.setShowSignUpModal(true) : props.setShowSignUpModal(false)
        }}
      >
        <div className="" >

          <div className="image-container  justify-content-between align-item-center  ">
            {item.fileType === "pdf" ?
              <a className="" >

{console.log(props.signTrigger)}
                <img
                  src={pdfImage}
                  className="item-image"
                  onClick={() => {
                    props.signTrigger === true ? null : props.setFileViewer(true),
                      props.setSelected(item)

                  }}
                />


                <OverlayTrigger trigger="click" placement="left" overlay={popover}>
                  <div className="dots-container bg-light px-2 p-2 rounded rounded-5" ref={target} onClick={handleClick}>
                    <span className="dot" ></span>
                    <span className="dot" ></span>
                    <span className="dot" ></span>

                  </div>
                </OverlayTrigger>
                {/* <div className="info">
                  <span> Download</span>
                  <span>Share </span>

                </div> */}
              </a>
              : item.fileType === "audio" ?
                <div
                className=''
                onClick={() => {
                  props.setFileViewer(true),
                    props.setSelected(item)
                }}>
                 <img
                  className="item-image "
                 width={"100%"}
                 src="https://res.cloudinary.com/ddf2undlu/image/upload/v1743718722/sound-audio-file-icon-illustration-logo-vector-audio-file-357937367_f6x0p4.webp"
                 />                </div> : item.fileType === "video" ?
                  <div onClick={() => {
                    props.setFileViewer(true),
                      props.setSelected(item)
                  }}>
                    <video
                      className="item-image"
                       //controls
                      src={item?.docURL	}
                      type="video/mp4"

                    />
                  </div> : item?.fileType === 'lecture' ?
                    <div>

                    </div> :
                    null}
            {/* <img
              src={item.imageUrl[0]?.docURL	}
              className="item-image"
            /> */}
          </div>

          <div className="item-info  py-1">
            {/* <p className="  ">
              Downloads  {item.downloadNumber}
            </p> */}



            <ul className='list-inline mb-0 font-size-10 d-flex flex-column '>
              <li className="list-inline-item text-black text-center  ">
                <b>  {item?.fileName}</b>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <Modal
        centered
        show={showShareModal}
        onHide={() => setShowShareModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary"> Share {item?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-lg-2">
            <Row className="no-gutters">
              <Col className="dropdown-icon-item">
                <FacebookShareButton
                  url={item?.docURL	}
                  quote="Experience Digital Advertising!"
                >
                  <FacebookIcon size={30} round />
                </FacebookShareButton>
                <span>Facebook</span>
              </Col>

              <Col className="dropdown-icon-item">
                <TwitterShareButton
                  url={item?.docURL	}
                  quote="Experience Digital Advertising!"
                >
                  <TwitterIcon size={30} round />
                </TwitterShareButton>
                <span>Twitter</span>
              </Col>

              <Col className="dropdown-icon-item">
                <WhatsappShareButton
                  url={item?.docURL	}
                  quote="Experience Digital Advertising!"
                >
                  <WhatsappIcon size={30} round />
                </WhatsappShareButton>
                <span>WhatsApp</span>
              </Col>
            </Row>

            <Row className="no-gutters">
              <Col className="dropdown-icon-item">
                <FacebookMessengerShareButton
                  url={item?.docURL	}
                  quote="Experience Digital Advertising!"
                >
                  <FacebookMessengerIcon size={30} round />
                </FacebookMessengerShareButton>
                <span>Messenger</span>
              </Col>
              <Col className="dropdown-icon-item">
                <TelegramShareButton
                  url={item?.docURL	}
                  quote="Experience Digital Advertising!"
                >
                  <TelegramIcon size={30} round />
                </TelegramShareButton>
                <span>Telegram</span>
              </Col>
              <Col className="dropdown-icon-item">
                <LinkedinShareButton
                  url={item?.docURL	}
                  quote="Experience Digital Advertising!"
                >
                  <LinkedinIcon size={30} round />
                </LinkedinShareButton>
                <span>LinkedIn</span>
              </Col>
            </Row>

            <Row className="no-gutters">
              <Col className="dropdown-icon-item">
                <EmailShareButton
                  url={item?.docURL	}
                  quote="Experience Digital Advertising!"
                >
                  <EmailIcon size={30} round />
                </EmailShareButton>
                <span>Email</span>
              </Col>
              <Col className="dropdown-icon-item">
                <div className="d-flex justify-content-center">
                  <div
                    onClick={() => {
                      navigator.clipboard.writeText(item?.docURL	);
                      setShowShareModal(false);
                      toast.success('Link copied to clipboard');
                    }}
                    className="avatar-xs"
                    style={{ cursor: 'pointer' }}
                  >
                    <span className="avatar-title rounded-circle bg-primary">
                      <i className='bx bx-copy-alt' />
                    </span>
                  </div>
                </div>
                <span>Copy</span>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
FileCard.propTypes = {
  item: propTypes.obj,
  signTrigger: propTypes.bool,
  downloadReady: propTypes.bool,
  setSignTrigger: propTypes.func,
  setFileViewer: propTypes.func,
  setSelected: propTypes.func,
}
export default FileCard