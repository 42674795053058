import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { Circles } from 'react-loader-spinner'

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "routes";
import Authmiddleware from "routes/route";

// Layouts
import VerticalLayout from "components/VerticalLayout/";
import HorizontalLayout from "components/HorizontalLayout/";
import NonAuthLayout from "components/NonAuthLayout";

// Toasts
import { Toaster } from "react-hot-toast";

// PDF Viewer worker
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Styles
import "assets/scss/theme.scss";

// Backend simulation
import fakeBackend from "helpers/AuthType/fakeBackend";
fakeBackend();

// Import action to fetch level types
import { fetchLevelTypesRequest } from "store/leveltype/actions";

const App = props => {
  const dispatch = useDispatch();

  const { loading } = useSelector(state => state.LevelType);


  
  useEffect(() => {
    // Dispatch the action to load level types when app loads
    dispatch(fetchLevelTypesRequest());
  }, [dispatch]);


  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
if(loading){ 
  return (
   <div 
   className="d-flex vh-100 justify-content-center align-items-center">
             <Circles
             height="100"
             width="100"
             color="#2D3194"
             ariaLabel="circles-loading"
              wrapperStyle={{}}
             wrapperClass=""
             visible={true}
            />
          
    </div>
    )}

  return (
    <React.Fragment>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.js">
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
          </Switch>
        </Router>
      </Worker>

      <Toaster
        toastOptions={{
          position: "top-right",
          className: "bg-primary fs-5 text-white",
          duration: 3000,
          icon: <i className="bx bx-info-circle" />,
          iconTheme: { primary: "none" },
          loading: {
            position: "top-center",
            icon: <i className="bx bx-sync bx-spin" />,
          },
          success: {
            position: "top-center",
            className: "bg-success fs-5 text-white",
            duration: 2000,
            icon: <i className="bx bx-check-circle" />,
            iconTheme: { primary: "none" },
          },
          error: {
            position: "top-right",
            className: "fs-5 text-white",
            duration: 4000,
            style: { backgroundColor: "red" },
            icon: <i className="bx bx-error" />,
            iconTheme: { primary: "none" },
          },
        }}
      />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
  leveltype: PropTypes.any,
};

const mapStateToProps = state => {
  //console.log("Redux LevelType State:", state.LevelType);
  return {
    layout: state.Layout,
    leveltype: state.LevelType,
  };
};

export default connect(mapStateToProps)(App);
