import React from 'react';
import { useHistory } from "react-router-dom";

const UnitsTopicsLayout = ({
  units,
  topics,
  selectedUnit,
  setSelectedUnit,
  setSelectedTopic,
  selectedSubTopic,
  setSelectedSubTopic,
  activeContentType,
  setActiveContentType,

}) => {
  const history = useHistory();

  const handleUnitClick = (index) => {
    setSelectedUnit(selectedUnit === index ? null : index);
  };

  const handleSubTopicClick = (index) => {
    setSelectedSubTopic(selectedUnit === index ? null : index);
    setSelectedTopic(index?.topic);
  };

  if (selectedUnit !== null) {
    return (
      <div className="unit-detail">
        {topics.length === 0 ? 
    <div className="d-flex text-primary w-100 justify-content-center   my-5">
      <h4>No Strands Available</h4>
    </div> :
        <div className="units-container">
          {topics.map((topic, i) => (
            <div key={i} className="unit-card">
              <h3 className='text-primary'>{topic.topic}</h3>
              <ul>
                {topic.subTopics.map((subtopic, j) => (
                  <li key={j}
                  onClick={() => {
                    if (activeContentType === "Learning Material") {
                      handleSubTopicClick(subtopic);
                    } else {
                      history.push(
                        `/quizes-and-tests/view/quiz/${subtopic?._id}`
                      );
                    }
                  }}
                  >
                    {subtopic?.subTopicName}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        }
      </div>
    );
  }

  return (
    <div className="units-container"
   >
    {units.length === 0 ? 
    <div className="d-flex text-primary w-100 justify-content-center   my-5">
      <h4>No Learning area Available</h4>
    </div> :<>
      {units.map((unit, index) => (
        <div
          key={index}
          className="unit-card"
          onClick={() => handleUnitClick(unit)}
        >
          <div className="unit-title text-primary">{unit.unit}</div>
          <ul className="topic-list ">
            {unit.topics.slice(0, 5).map((topic, i) => (
              <li className='text-black fs-6' key={i}>{topic?.topicName}</li>
            ))}
          </ul>
          {unit?.topics.length > 5 && (
            <div className="view-more">
              View All
            </div>
          )}
        </div>
      ))}
      </>
      }
    </div>
  );
};

export default UnitsTopicsLayout;
