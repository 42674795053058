// store/leveltype/reducer.js

import {
    FETCH_LEVELTYPES_REQUEST,
    FETCH_LEVELTYPES_SUCCESS,
    FETCH_LEVELTYPES_FAILURE,
  } from "./actionTypes";
  
  const initialState = {
    loading: false,
    data: [],
    error: null,
  };
  
  const leveltypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_LEVELTYPES_REQUEST:
        return { ...state, loading: true, error: null };
  
      case FETCH_LEVELTYPES_SUCCESS:
        return { ...state, loading: false, data: action.payload };
  
      case FETCH_LEVELTYPES_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  
  export default leveltypeReducer;
  