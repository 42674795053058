import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { Circles } from 'react-loader-spinner'

const LandingPage = (props) => {
  const [showExplore, setShowExplore] = useState(false)
  return (
    <div className="landing-page">

      {/* Level Types Section */}
      {showExplore ? (
        <section className="level-types">
        {props.levelTypes.length>0?(<>
          <h2 className="text-center text-primary">Explore by Learning Levels</h2>
        <div className="course-cards-container">
          {props.levelTypes.map((levelType) => (
            <div key={levelType._id} className="level-type">
              <h2 className="level-header">{levelType.levelTypeName}</h2>
              <Row className="course-cards">
                {levelType.levels.map((level) => (
                  <Col key={level.id}>
                    <div className="course-card">
                      <h3>{level.levelName}</h3>
                      <p>{`Explore in-depth content for ${level.levelName}.`}</p>
                      <button className="btn start-learning-btn"
                        onClick={() => { props.setSelectedLevel(level?.levelName) }}
                      >Start Learning</button>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          ))}
        </div>
        </>):(
          <div className="d-flex p-5 vh-100 my-5 justify-content-center">
          <Circles
           height="100"
           width="100"
           color="#2D3194"
           ariaLabel="circles-loading"
            wrapperStyle={{}}
           wrapperClass=""
           visible={true}
          />
        
          </div>)}
       
      </section>) : (
        <>
          {/* Hero Section */}
          <section className="hero">
            <div className="hero-content">
              <h1>Unlock Your Potential with Tehuti Learning Hub</h1>
              <p>Learn from academic experts and achieve your academic goals at your own pace.</p>
              <div className="cta-buttons">
                <button className="btn cta-btn join-now"
                  onClick={() => props.setShowSignUpModal(true)}>Join Now</button>
                <button className="btn mt-2 mt-md-0 cta-btn browse-courses"
                  onClick={() => { setShowExplore(true) }}>Browse Content</button>
              </div>
            </div>
          </section>

          {/* Features Section */}
          <section className="features">
            <h2>Why Choose Us?</h2>
            <div className="feature-cards">
              <div className="feature-card">
                <div className="card-icon">📚</div>
                <h3>Self-paced Learning</h3>
                <p>Flexible schedules to match your lifestyle.</p>
              </div>
              <div className="feature-card">
                <div className="card-icon">🎓</div>
                <h3>Practice Quizzes</h3>
                <p>Interactive quizzes to boost your understanding.</p>
              </div>
              <div className="feature-card">
                <div className="card-icon">💰</div>
                <h3>Accessible Pricing</h3>
                <p>Affordable plans for every budget.</p>
              </div>
              <div className="feature-card">
                <div className="card-icon">📺</div>
                <h3>Live Lectures</h3>
                <p>Engage with experts in real-time sessions.</p>
              </div>
            </div>
          </section>

        </>
      )}

    </div>
  );
};

LandingPage.propTypes = {
  levelTypes: PropTypes.array,
  setSelectedLevel: PropTypes.func,
  setShowSignUpModal: PropTypes.func,
};

export default LandingPage;
