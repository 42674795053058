// store/leveltype/actions.js

import {
  FETCH_LEVELTYPES_REQUEST,
  FETCH_LEVELTYPES_SUCCESS,
  FETCH_LEVELTYPES_FAILURE,
} from "./actionTypes";

// Action to request level types
export const fetchLevelTypesRequest = () => ({
  type: FETCH_LEVELTYPES_REQUEST,
});

// Action to handle success
export const fetchLevelTypesSuccess = (data) => ({
  type: FETCH_LEVELTYPES_SUCCESS,
  payload: data,
});

// Action to handle error
export const fetchLevelTypesFailure = (error) => ({
  type: FETCH_LEVELTYPES_FAILURE,
  payload: error,
});
