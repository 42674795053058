// store/leveltype/saga.js

import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_LEVELTYPES_REQUEST,
} from "./actionTypes";

import {
  fetchLevelTypesSuccess,
  fetchLevelTypesFailure,
} from "./actions";

import { getApi } from "helpers/api.config";

// API call mock (replace with actual API endpoint)
async function fetchLevelTypesApi() {
  const res = await fetch(`${getApi.SETUP.GET_ALL_LEVELTYPES}`);
  return await res.json();
}

// Worker saga
function* handleFetchLevelTypes() {
  try {
    const data = yield call(fetchLevelTypesApi);
    yield put(fetchLevelTypesSuccess(data));
  } catch (error) {
    yield put(fetchLevelTypesFailure(error.message));
  }
}

// Watcher saga
export default function* leveltypeSaga() {
  yield takeLatest(FETCH_LEVELTYPES_REQUEST, handleFetchLevelTypes);
}
