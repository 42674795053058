import React, { useEffect, useRef } from "react";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

export const VideoViewer = ({ item }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    if (!videoRef.current) return;

    // 🛑 Destroy previous instance if it exists
    if (playerRef.current) {
      playerRef.current.destroy();
      playerRef.current = null;
    }

    // 🎥 Initialize Plyr instance
    playerRef.current = new Plyr(videoRef.current, {
      controls: ["play", "progress", "current-time", "mute", "volume", "fullscreen"],
    });

  }, []);

  useEffect(() => {
    if (playerRef.current && item?.docURL) {
      // 🔄 Update the video source manually
      playerRef.current.source = {
        type: "video",
        sources: [{ src: item.docURL, type: "video/mp4" }],
      };
    }
  }, [item?.docURL]); // Runs when `item.docURL` changes

  return (
    <div className="item-image bg-warning">
    {item?.docURL ? (
      <video
       poster="https://res.cloudinary.com/ddf2undlu/video/upload/v1732870412/mov_bbb.mp4"
        style={{
          width: "100%",         // Full width of the parent container
        //  height: "100%",        // Automatically adjusts height to maintain aspect ratio
          maxWidth: "640px",     // Maximum width (you can change this value)
          maxHeight: "60vh",    // Maximum height (you can change this value)
        }}
        ref={videoRef}
        className="plyr-react plyr"
        controls
      >
        <source src={item.docURL} type="video/mp4" />
      </video>
    ) : (
      <p>No video available</p>
    )}
  </div>
  
  );
};
