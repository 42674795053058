import React, { useState, useRef, useEffect } from "react";

const Audio = ({
  selected,
  setSelected
}) => {
  const [index, setIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [pause, setPause] = useState(false);
  const playerRef = useRef(null);
  const timelineRef = useRef(null);
  const playheadRef = useRef(null);
  const hoverPlayheadRef = useRef(null);

  const musicList = [
    {
      name: "Nice piano and ukulele",
      author: "Royalty",
      img: "https://res.cloudinary.com/ddf2undlu/image/upload/v1743718722/sound-audio-file-icon-illustration-logo-vector-audio-file-357937367_f6x0p4.webp",
      audio: "https://www.bensound.com/bensound-music/bensound-buddy.mp3",
      duration: "2:02",
    },
    {
      name: "Slow cinematic",
      author: "Royalty",
      img: "https://www.bensound.com/bensound-img/slowmotion.jpg",
      audio: "https://www.bensound.com/bensound-music/bensound-slowmotion.mp3",
      duration: "3:26",
    },
  ];

  useEffect(() => {
    const player = playerRef.current;
    if (!player) return;

    const timeUpdate = () => {
      const duration = player.duration;
      if (!duration) return;

      const playPercent = (100 * player.currentTime) / duration;
      playheadRef.current.style.width = playPercent + "%";
      setCurrentTime(formatTime(Math.floor(player.currentTime)));
    };

    const nextSong = () => {
      setIndex((prevIndex) => (prevIndex + 1) % musicList.length);
    };

    player.addEventListener("timeupdate", timeUpdate);
    player.addEventListener("ended", nextSong);

    return () => {
      player.removeEventListener("timeupdate", timeUpdate);
      player.removeEventListener("ended", nextSong);
    };
  }, [index]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = String(time % 60).padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const playOrPause = () => {
    if (pause) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
    setPause(!pause);
  };

  const changeCurrentTime = (e) => {
    const player = playerRef.current;
    const duration = player.duration;
    const playheadWidth = timelineRef.current.offsetWidth;
    const offsetWidth = timelineRef.current.offsetLeft;
    const userClickWidth = e.clientX - offsetWidth;
    const userClickWidthInPercent = (userClickWidth * 100) / playheadWidth;

    playheadRef.current.style.width = userClickWidthInPercent + "%";
    player.currentTime = (duration * userClickWidthInPercent) / 100;
  };

  const clickAudio = (key) => {
    setIndex(key);
    if (pause) {
      playerRef.current.play();
    }
  };

  return (
    <div className="audio-card   w-100">
      <div className="current-song">
        <audio ref={playerRef}>
          <source src={selected.docURL} type="audio/ogg" />
          Your browser does not support the audio element.
        </audio>
        <div className="img-wrap">
          <img src={'https://res.cloudinary.com/ddf2undlu/image/upload/v1743718722/sound-audio-file-icon-illustration-logo-vector-audio-file-357937367_f6x0p4.webp'} alt={selected.name} />
        </div>
        <span className="song-name">{selected?.fileName}</span>
        <span className="song-author">{selected.author}</span>

        <div className="time">
          <div className="current-time">{currentTime}</div>
          <div className="end-time">{selected?.duration}</div>
        </div>

        <div ref={timelineRef} id="timeline" onClick={changeCurrentTime}>
          <div ref={playheadRef} id="playhead"></div>
          <div ref={hoverPlayheadRef} className="hover-playhead" data-content="0:00"></div>
        </div>

        <div className="controls">
          <button onClick={() => setIndex((index + musicList.length - 1) % musicList.length)} className="prev prev-next current-btn">
            <i className="fas fa-backward"></i>
          </button>

          <button onClick={playOrPause} className="play current-btn">
            {!pause ? <i className="fas fa-play"></i> : <i className="fas fa-pause"></i>}
          </button>

          <button onClick={() => setIndex((index + 1) % musicList.length)} className="next prev-next current-btn">
            <i className="fas fa-forward"></i>
          </button>
        </div>
      </div>

    
    </div>
  );
};

export default Audio;
