
// main API URL
let baseUrl = "https://tehuti-learning-hub-api.onrender.com";
//"https://tehuti-learning-hub-api.onrender.com";
// "https://tehuti-apis.onrender.com";
//"https://api.iq.steticduka.co.ke";
// "http://localhost:3001"
//"http://localhost:3009"
//"https://api.steticduka.co.ke";

export const getApi = {
  AUTH:{
    VERIFY_OTP: `${baseUrl}/auth/student/verify-otp`,
    SEND_OTP: `${baseUrl}/auth/student/send-otp`,
    REGISTER: `${baseUrl}/auth/student/register`,
    VERIFY_REGISTRATION:`${baseUrl}/auth/student/verify-registration`,
    LOGOUT:`${baseUrl}/auth/student/logout`,
    REFRESH_TOKEN:`${baseUrl}/auth/student/refresh-token`,
    GET_PROFILE:`${baseUrl}/auth/student/profile`,
    UPDATE_PROFILE:`${baseUrl}/auth/student/profile`,
    DELETE_PROFILE:`${baseUrl}/auth/student/profile`,
    VERIFY_DELETE_PROFILE:`${baseUrl}/auth/student/profile/verify-delete`,
  },

  LOGS: {
    // items
    GET_ALL_LOGS: `${baseUrl}/logs/getAllLogs`,
    GET_LOG_BY_ID: `${baseUrl}/logs/getLogById`,



  },
  UPLOAD: {
    //GET
    GET_CONTENTS: `${baseUrl}/uploads/getContentUploadsByOwner`,
    GET_FILE_BY_ID: `${baseUrl}/uploads/getFileById`,

    //POST
    ADD_FILE: `${baseUrl}/uploads/addFile`,
    UPLOAD_FILE: `${baseUrl}/uploads/uploadFile`,
    //PUT
    UPDATE_FILE: `${baseUrl}/uploads/updateFile`,
    //DELETE
    DELETE_FILE: `${baseUrl}/uploads/deleteFile`,
  },

  SETUP: {
    //levelTypes
    GET_ALL_LEVELTYPES: `${baseUrl}/level-types`,
    GET__LEVELTYPES_BY_ID: `${baseUrl}/level-types`,
    //POST
    ADD_LEVELTYPE: `${baseUrl}/level-types`,
    //PUT
    UPDATE_LEVELTYPE: `${baseUrl}/level-types`,
    //DELETE
    DELETE_LEVELTYPE: `${baseUrl}/level-types`,

    //level
    GET_ALL_LEVELS: `${baseUrl}/levels`,
    GET__LEVEL_BY_ID: `${baseUrl}/levels`,
    GET_LEVEL_BY_NAME: `${baseUrl}/levels`,
    //POST
    ADD_LEVEL: `${baseUrl}/levels`,
    //PUT
    UPDATE_LEVEL: `${baseUrl}/levels`,
    //DELETE
    DELETE_LEVEL: `${baseUrl}/levels`,

    //units
    GET_ALL_UNITS: `${baseUrl}/units`,
    GET__UNIT_BY_ID: `${baseUrl}/units`,
    //POST
    ADD_UNIT: `${baseUrl}/units`,
    ADD_TOPIC: `${baseUrl}/units`,
    //PUT
    UPDATE_UNIT: `${baseUrl}/units`,
    //DELETE
    DELETE_UNIT: `${baseUrl}/units`,


    //topics
    GET_ALL_TOPICS: `${baseUrl}/topics`,
    GET__TOPIC_BY_ID: `${baseUrl}/units`,
    GET_ALL_CONTENTS: `${baseUrl}/contents/getAllContents`,
    //POST
    ADD_TOPIC: `${baseUrl}/topics`,
    UPLOAD_CONTENT: `${baseUrl}/contents/addContent`,
    //PUT
    UPDATE_TOPIC: `${baseUrl}/topics`,
    //DELETE
    DELETE_TOPIC: `${baseUrl}/topics`,

    //subTopics
    GET_ALL_SUB_TOPICS: `${baseUrl}/sub-topics`,
    GET__SUB_TOPIC_BY_ID: `${baseUrl}/sub-topics`,
    //POST
    ADD_SUB_TOPIC: `${baseUrl}/sub-topics`,
    //PUT
    UPDATE_SUB_TOPIC: `${baseUrl}/sub-topics`,
    //DELETE
    DELETE_SUB_TOPIC: `${baseUrl}/sub-topics`,



    //quizes
    GET_ALL_QUIZES: `${baseUrl}/tests`,
    GET_QUIZ_BY_ID: `${baseUrl}/tests`,
    //POST
    ADD_QUIZ: `${baseUrl}/tests`,
    //PUT
    UPDATE_QUIZ: `${baseUrl}/tests`,
    //DELETE
    DELETE_QUIZ: `${baseUrl}/tests`,

    //Tests
    GET_ALL_TESTS: `${baseUrl}/tests`,
    GET_TEST_BY_ID: `${baseUrl}/tests`,
    //POST
    ADD_TEST: `${baseUrl}/tests`,
    //PUT
    UPDATE_TEST: `${baseUrl}/tests`,
    //DELETE
    DELETE_TEST: `${baseUrl}/tests`,
  },

  LECTURES: {
    //Tests
    GET_ALL_LECTURES: `${baseUrl}/lectures/getAllLectures`,
    GET_LECTURE_BY_ID: `${baseUrl}/lectures/getLecturetById`,
    //POST
    ADD_LECTURE: `${baseUrl}/lectures/addLecture`,
    JOIN_LECTURE: `${baseUrl}/lectures/joinLecture`,
    //PUT
    UPDATE_LECTURE: `${baseUrl}/lectures/updateLecture`,
    //DELETE
    DELETE_LECTURE: `${baseUrl}/lectures/deleteLecture`,
  },

  ANALYTICS: {
    //category
    GET_ANALYTICS: `${baseUrl}/analytics/getAnalytics`,


  }

};
