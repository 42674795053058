import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Spinner, InputGroup } from "react-bootstrap"
import toast from "react-hot-toast";
import Select from "react-select";
import { Link } from "react-router-dom"

import * as setup from "models/setup";
import * as levelType from "models/leveltypes";

import * as auth from "models/auth"

const AuthPop = props => {
  const [levelTypes, setLevelTypes] = useState([])
  const [levels, setLevels] = useState([])

  const [selectedUserType, setSelecteduserType] = useState()
  const [selectedLevelType, setSelectedLevelType] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedGender, setSelectedGender] = useState(null)

  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [timer, setTimer] = useState(60); // Timer in seconds
  const [canResend, setCanResend] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  const [showPword, setShowPword] = useState(false)
  const [isLoading, setIsLoading] = useState()

  const [inputType, setInputType] = useState("email");

  const gender = [{
    label: "Gender",
    options: [{ label: "Male", value: "male" }, { label: "Female", value: "female" }]
  }]

  const splitName=(fullName)=> {
    if (!fullName) return { firstName: "", middleName: "", lastName: "" };
  
    const parts = fullName.trim().split(/\s+/);
    const firstName = parts[0] || "";
    const lastName = parts.length > 1 ? parts[parts.length - 1] : "";
    const middleName = parts.length > 2 ? parts.slice(1, -1).join(" ") : "";
  
    return { firstName, middleName, lastName };
  }

  const closeModal = () => {
    props.setShowModal(false)
    props.setShowSignUp(false)
    setIsLoading(false)
    setInputType('email')
    setOtpSent(false)
  }

  const handleSelectGender = (selectedGender) => {
    setSelectedGender(selectedGender)
  }

  const handleSelectLevelType = (selectedLevelType) => {
    setSelectedLevelType(selectedLevelType)
    //getAllSubCategories(selectedCategory?.label)
  }

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel)
  }



  const handleResendOtp = () => {
    setCanResend(false); // Disable resend
    setTimer(60); // Start a new 60-second timer

    const payload = {
      ...(inputType === 'email'
        ? { emailAddress: email }
        : {}),
      ...(inputType === 'phone'
        ? { phoneNumber: phoneNumber}
        : {}),
      }

      auth.sendOtp(payload)
      .then(resp => {
        toast.success(resp?.message)
        setOtpSent(true)
        setEmail(form.userEmail.value)
        setIsLoading(false)
        setTimer(60)
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })


  };

  const sendOtp = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    const payload = {
      ...(inputType === 'email'
        ? { emailAddress: form.userEmail.value, }
        : {}),
      ...(inputType === 'phone'
        ? { phoneNumber: form.phoneNumber.value, }
        : {}),

    }
    auth.sendOtp(payload)
      .then(resp => {
        toast.success(resp?.message)
        setOtpSent(true)
        setEmail(form.userEmail.value)
        setIsLoading(false)
        setTimer(60)
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }

  const verifyOtp = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    const payload = {
      ...(inputType === 'email'
        ? { emailAddress: form.userEmail.value, }
        : {}),
      ...(inputType === 'phone'
        ? { phoneNumber: form.phoneNumber.value, }
        : {}),

      otp: form.otp.value,
    }
    auth.verifyOtp(payload)
      .then(resp => {
        setIsLoading(false)
        const date = Date.now()
        setIsLoading(false)
      
        {
          resp?.student?.role === "Student" ? (
        localStorage.setItem("teutiUser", JSON.stringify(resp?.student)),
        localStorage.setItem("Token", JSON.stringify(resp?.tokens)),
            props.setUpdateLocalStorage(date),
            closeModal()) : (toast.error("You are not allowed to use this portal"))
        }
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }

  const handleRegister = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    const payload = {
      firstName: splitName(form.fullName.value).firstName,
      middleName: splitName(form.fullName.value).middleName,
      lastName: splitName(form.fullName.value).lastName,
      emailAddress: form.userEmail.value,
      level: selectedLevel?.value,
      levelType: selectedLevelType?.value,
      phoneNumber: form.phoneNumber.value,
      location: form.location.value,
      sex: selectedGender?.value,
    }
    console.log("payload",payload)
    auth.register(payload)
      .then(resp => {
        const data = resp.profile
        setIsLoading(false)
        closeModal()
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }
  const getAllLevelTypes = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    levelType.getAllLevelTypes(params, token)
      .then(resp => {
        const data = resp.results
        setLevelTypes([
          {
            label: "Level Tpyes",
            options: data.map(item => ({
              label: item?.levelTypeName,
              value: item?.levelTypeName,
              levels: item?.levels
            }))
          }
        ])
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getAllLevelTypes()
  }, [])

  useEffect(() => {
    setLevels([
      {
        label: "Level ",
        options: selectedLevelType?.levels.map(item => ({
          label: item?.levelName,
          value: item?.levelName,
        }))
      }
    ])
  }, [selectedLevelType])

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
    } else {
      setCanResend(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div>
      <Modal
        centered
        show={props.showModal}
        onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Log in or sign up
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.showSignUp ? (
            <Form
              onSubmit={handleRegister}
              className=""
            >
              <Form.Group className="mb-3" controlId="fullName">
                <Form.Label>Full Name</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="text"
                    placeholder="e.g. John Doe"
                    required
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="userEmail">
                <Form.Label>User Email</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="email"
                    placeholder="e.g. johndoe@email.com"
                    required
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid Email
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="number"
                    placeholder="e.g. 0712345678"
                    required
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid
                </Form.Control.Feedback>
              </Form.Group>


              <Form.Group className="mb-3" controlId="levelType">
                <Form.Label>
                  Level Type
                </Form.Label>
                <Select
                  value={selectedLevelType}
                  onChange={handleSelectLevelType}
                  options={levelTypes}
                />
              </Form.Group>
              {selectedLevelType?.value === undefined ? null : (<Form.Group className="mb-3" controlId="level">
                <Form.Label>
                  Level
                </Form.Label>
                <Select
                  value={selectedLevel}
                  onChange={handleSelectLevel}
                  options={levels}
                />
              </Form.Group>)}
              <Form.Group className="mb-3" controlId="level">
                <Form.Label>
                  Sex
                </Form.Label>
                <Select
                  value={selectedGender}
                  onChange={handleSelectGender}
                  options={gender}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="location">
                <Form.Label>Location</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="text"
                    placeholder="e.g. Nairobi"
                    required
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid
                </Form.Control.Feedback>
              </Form.Group>

              <div className="mt-4 d-grid ">
                <button
                  className="btn btn-primary btn-block btn-lg rounded-0"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Processing...
                    </>
                  ) : (
                    "Create Account"
                  )}
                </button>
              </div>
              <div className="d-flex justify-content-center mb-3 mt-2">
                <a className="text-primary"
                  onClick={() => props.setShowSignUp(true)}>
                  Already have an account?
                </a>
              </div>
            </Form>
          ) : (
            <div>
              {!otpSent ? (<Form onSubmit={sendOtp} className="">
                {/* Toggle to select Email or Phone */}
                <Form.Group className="mb-3" controlId="inputType">
                  <Form.Label>Choose Sign in option.</Form.Label>
                  <div className="d-flex gap-3">
                    <div className="form-check me-2">
                      <input className="form-check-input"
                        onChange={(e) => { setInputType(e.target.value), console.log(inputType) }}
                        type="radio"
                        defaultChecked
                        name="flexRadioDisabled"
                        value={"email"}
                        id="flexRadioDisabled" />
                      <label className="form-check-label" >
                        Email
                      </label>
                    </div>
                    <div className="form-check me-2">
                      <input className="form-check-input"
                        onChange={(e) => { setInputType(e.target.value), console.log(inputType) }}
                        type="radio"
                        name="flexRadioDisabled"
                        value={"phone"}
                        id="flexRadioDisabled" />
                      <label className="form-check-label" >
                        Phone Number
                      </label>
                    </div>
                  </div>
                </Form.Group>

                {/* Email Input */}
                {inputType === "email" && (
                  <Form.Group className="mb-3" controlId="userEmail">
                    <Form.Label>User Email</Form.Label>
                    <InputGroup className="form-control-lgm">
                      <Form.Control
                        type="email"
                        placeholder="e.g. johndoe@email.com"
                        defaultValue={email}
                        required
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      Invalid Email
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                {/* Phone Number Input */}
                {inputType === "phone" && (
                  <Form.Group className="mb-3" controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup className="form-control-lgm">
                      <Form.Control
                        type="text"
                        placeholder="e.g. 0712345678"
                        required
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      Invalid Phone Number
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                <div className="mt-4 d-grid">
                  <button
                    className="btn btn-primary btn-block btn-lg rounded-0"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Processing...
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
                <div className="d-flex justify-content-center mb-3 mt-2">
                  <a
                    className="text-primary"
                    onClick={() => props.setShowSignUp(true)}
                  >
                    Create an account.
                  </a>
                </div>
              </Form>) : (<Form
                onSubmit={verifyOtp}
                className=""
              >
                {/* Email Input */}
                {inputType === "email" && (
                  <Form.Group className="mb-3" controlId="userEmail">
                    <Form.Label>User Email</Form.Label>
                    <InputGroup className="form-control-lgm">
                      <Form.Control
                        type="email"
                        placeholder="e.g. johndoe@email.com"
                        value={email}
                        required
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      Invalid Email
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                {/* Phone Number Input */}
                {inputType === "phone" && (
                  <Form.Group className="mb-3" controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup className="form-control-lgm">
                      <Form.Control
                        type="text"
                        value={phoneNumber}
                        placeholder="e.g. 0712345678"
                        required
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      Invalid Phone Number
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="otp">
                  <Form.Label>OTP</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Enter Otp"
                      required
                      className=""
                    />

                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Invalid Password
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <button
                    onClick={()=>handleResendOtp()}
                    disabled={!canResend}
                    className="btn border-0  p-0 "
                  >
                    Resend OTP
                  </button>
                  {!canResend && (
                    <span className="text-muted small">
                      Resend available in {timer} seconds
                    </span>
                  )}
                </div>

                <div className="mt-4 d-grid ">
                  <button
                    className="btn btn-primary btn-block btn-lg rounded-0"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Processing...
                      </>
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
                <div className="d-flex justify-content-center mb-3 mt-2">
                  <a className="text-primary"
                    onClick={() => props.setShowSignUp(true)}>
                    Create an account.
                  </a>
                </div>
              </Form>)}



            </div>


          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

AuthPop.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  showSignUp: PropTypes.bool,
  setShowSignUp: PropTypes.func,
}

export default AuthPop